<template>
  <div @click="setOrder">
    <div
      class="orders-list"
      :class="{ active: active === product.id }"
      @click="setActive(product)"
      v-for="(product, key) in products"
      :key="key"
      v-show="product.name !== undefined"
    >
      <div class="col orders-list__date" style="width: 150px;">
        {{ order.creationDate | moment("DD.MM.YYYY") }}
      </div> 

      <div class="col" style="width: 120px;">{{ order.id }}</div>
      <div class="col" style="width: 120px;">{{ getShipper(product) }}</div>

      <div class="col" style="width: 250px;" @mouseover="showFullName = true" @mouseleave="showFullName = false" >
        {{ truncLongString(order.user.full_name, 20) }}
        <span v-show="showFullName" class="helper">{{order.user.full_name}}</span>
      </div>
      <div
        @click="activeInput = 'id'"
        class="col orders-list__doc_id"
        style="width: 140px;"
        :class="{ saved: savedInput === 'report' }"
      >
        <input
          class="orders-list__no-border-text"
          type="number"
          maxlength="15"
          v-model="productReports[product.id].documentNumber"
        />
        <div class="buttons-wrap">
          <button
            v-if="activeInput === 'id'"
            class="button--save"
            @click="saveDocument(product)"
          >
            ok
          </button>
          <button
            v-if="activeInput === 'id'"
            class="button--save"
            @click="deleteDocument(product)"
          >
            del
          </button>
        </div>
      </div>
      <div
        @click="activeInput = 'date'"
        class="col orders-list__doc_date"
        style="width: 130px;"
        :class="{ saved: savedInput === 'report' }"
      >
        <datepicker
          :language="ru"
          :format="customFormatter"
          :disabled-dates="disabledDatesStart"
          input-class="dateInput"
          wrapper-class="dateInputWrap"
          v-model="productReports[product.id].date"
          @selected="dateChange"
        />
        <div class="buttons-wrap">
          <button
            v-if="activeInput === 'date'"
            class="button--save"
            @click="saveDocument(product)"
          >
            ok
          </button>
          <button
            v-if="activeInput === 'date'"
            class="button--save"
            @click="deleteDocument(product)"
          >
            del
          </button>
        </div>
      </div>
      <div
        @click="activeInput = 'numberTTN'"
        class="col orders-list__doc_id"
        style="width: 140px;"
        :class="{ saved: savedInput === 'report' }"
      >
        <input
          class="orders-list__no-border-text"
          type="number"
          maxlength="15"
          v-model="productReports[product.id].numberTTN"
        />
        <div class="buttons-wrap">
          <button
            v-if="activeInput === 'numberTTN'"
            class="button--save"
            @click="saveDocument(product)"
          >
            ok
          </button>
          <button
            v-if="activeInput === 'numberTTN'"
            class="button--save"
            @click="deleteDocument(product)"
          >
            del
          </button>
        </div>
      </div>
      <div
        @click="activeInput = 'dateTTN'"
        class="col orders-list__doc_date"
        style="width: 120px;"
        :class="{ saved: savedInput === 'report' }"
      >
        <datepicker
          :language="ru"
          :format="customFormatter"
          :disabled-dates="disabledDatesStart"
          input-class="dateInput"
          wrapper-class="dateInputWrap"
          v-model="productReports[product.id].dateTTN"
          @selected="dateChange"
        />

        <div class="buttons-wrap">
          <button
            v-if="activeInput === 'dateTTN'"
            class="button--save"
            @click="saveDocument(product)"
          >
            ok
          </button>
          <button
            v-if="activeInput === 'dateTTN'"
            class="button--save"
            @click="deleteDocument(product)"
          >
            del
          </button>
        </div>
      </div>
      <div
        @click="activeInput = 'checked'"
        class="col orders-list__checked"
        style="width: 120px;"
        :class="{ saved: savedInput === 'report' }"
      >
        <input type="checkbox" v-model="productReports[product.id].checked" />
        <button
          v-if="activeInput === 'checked'"
          class="button--save"
          @click="saveDocument(product)"
        >
          ok
        </button>
      </div>
      <div
        class="col orders-list__status"
        style="width: 150px; text-align: center"
      >
        {{ convertStatus(order.status) }}
      </div>
      <div class="col orders-list__product" style="width: 150px;" @mouseover="showFullProductText = true" @mouseleave="showFullProductText = false">
        {{ truncLongString(product.name, 10) }}
       <span v-show="showFullProductText" class="helper">{{product.name}}</span>
      </div>
      <div
        @click="activeInput = 'price'"
        class="col orders-list__price"
        :class="{ saved: savedInput === 'price' }"
        style="width: 120px;"
      >
        <input
          class="orders-list__no-border-text"
          type="number"
          v-model="product.real_price"
        />
        <button
          v-if="activeInput === 'price'"
          @click="updatePrice(product)"
          class="button--save"
        >
          ok
        </button>
      </div>
      <div
        class="col"
        style="width: 120px"
        @click="activeInput = 'payment_type'"
      >
        <select v-model="order.paymentType">
          <option value="-">-</option>
          <option value="nal">Нал</option>
          <option value="beznal">Безнал</option>
        </select>
        <button
          v-if="activeInput === 'payment_type'"
          class="button--save"
          @click="updatePaymentType"
        >
          ok
        </button>
      </div>

      <div class="col" style="width: 170px;">
        {{ convert(order.user.phone) }}
      </div>
    </div>
  </div>
</template>
<script>
import convertPhone from "@/utils/convertPhone";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { ru } from "vuejs-datepicker/dist/locale";
import Vue from "vue";
import { truncateString } from "@/utils";

export default {
  props: {
    order: {
      required: true,
    },
    options: {
      required: true,
    },
    status: {
      required: true,
    },
  },
  data() {
    return {
      showFullName: false,
      showFullProductText: false,

      ru: ru,
      active: -1,
      activeInput: null,
      savedInput: null,
      isDateChanged: false,
      disabledDatesStart: {
        from: moment().toDate(),
      },
      disabledDatesEnd: {
        to: moment()
          .subtract(1, "months")
          .toDate(),
      },
      productReports: {},
      report: {
        id: null,
        documentNumber: null,
        date: null,
        numberTTN: null,
        dateTTN: null,
        checked: false,
      },
    };
  },
  components: {
    Datepicker,
  },
  computed: {
    products() {
      // выделяем кастомные товары
      if (this.order.purchases && this.order.purchases.customGoods) {
        var newOrder = this.order.purchases.customGoods.map((product) => {
          product.custom = true;
          return product;
        });
      }
      return this.order ? this.order.purchases.goods.concat(newOrder) : false;
    },
    maxStartDate() {
      return moment().format("DD-MM-YYYY");
    },
  },

  methods: {
    truncLongString(str, lenght) {
      return truncateString(str, lenght);
    },
    report_(product) {
      try {
        this.report.id = this.productReports[product.id].id;
        this.report.documentNumber = this.productReports[
          product.id
        ].documentNumber;
        this.report.date = this.productReports[product.id].date;
        this.report.numberTTN = this.productReports[product.id].numberTTN;
        this.report.dateTTN = this.productReports[product.id].dateTTN;
        this.report.checked = this.productReports[product.id].checked;
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log(e);
      }
      return this.report;
    },

    setActive(product) {
      this.active = product.id;
    },

    dateChange() {
      this.isDateChanged = true;
    },

    updatePaymentType() {
      const requestBodyParameters = {
        orderId: this.order.id,
        paymentType: this.order.paymentType,
      };

      Vue.http_admin
        .put(`api/order/${this.order.id}`, requestBodyParameters)
        .then(() => {
          console.log("paymentType updated successfully!");
          this.$emit("savedString", true);
        })
        .catch((error) => {
          this.$emit("savedString", false);
          console.log("SOME ERROR OCCURED!");
          console.log(error);
        });
    },

    updatePrice(product) {
      if (product.real_price.length === 0) {
        product.real_price = null;
      }
      let custom = false;
      if (product.custom) {
        custom = product.custom;
      }

      const bodyParameters = {
        custom: custom,
        orderId: this.order.id,
        real_price: product.real_price,
      };

      Vue.http_admin
        .put(`api/order/product/${product.id}`, bodyParameters)
        .then(() => {
          this.active = product.id;
          this.savedInput = "price";
          this.$emit("savedString", this.savedInput);
        })
        .catch((error) => {
          this.$emit("savedString", false);
          console.log(error);
        });
    },

    deleteDocument(product) {
      if (this.productReports[product.id]) {
        this.report_(product);
      }

      Vue.http_admin
        .put(`api/order/report/delete`, this.report)
        .then(() => {
          this.savedInput = "report";
          this.productReports[product.id] = {
            id: null,
            documentNumber: null,
            date: null,
            numberTTN: null,
            dateTTN: null,
            checked: false,
          };
          this.$emit("savedString", this.savedInput);
        })
        .catch((error) => {
          this.$emit("savedString", false);
          console.log(error);
        });
    },

    saveDocument(product) {
      this.report.orderId = this.order.id;
      if (this.productReports[product.id]) {
        this.report_(product);
      }

      if (!this.report.documentNumber) {
         this.$toast.error("Введите номер/дату списания!", {
              timeout: 2000
            })
        return;
      }

      if (product.custom) {
        this.report.customGoodId = product.id;
      } else {
        this.report.goodId = product.id;
      }

      Vue.http_admin
        .put(`api/order/report`, this.report)
        .then((response) => {
          if (response.data.id) {
            this.productReports[product.id].id = response.data.id;
            this.productReports[
              product.id
            ].documentNumber = this.report.documentNumber;
            this.productReports[product.id].date = this.report.date;
            this.productReports[product.id].numberTTN = this.report.numberTTN;
            this.productReports[product.id].dateTTN = this.report.dateTTN;
            this.productReports[product.id].checked = this.report.checked;
          }

          this.savedInput = "report";
          this.$emit("savedString", this.savedInput);
        })
        .catch((error) => {
          this.$emit("savedString", false);
          console.log(error);
        });
    },

    getShipper(product) {
      if (product.shipper) {
        return product.shipper;
      }
      return "ТехноБомба";
    },
    setOrder() {
      this.$emit("getOrder", this.order);
    },

    convertStatus(status) {
      switch (status) {
        case 10:
          return "Выбран";
        case 20:
          return "На сборке";
        case 30:
          return "В пути";
        case 40:
          return "Доставлен";
        case 50:
          return "Отменен";
      }
    },

    customFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    convert(num) {
      return convertPhone(num);
    },
  },

  created() {
    this.products.map((product) => {
      this.productReports[product.id] = {
        id: null,
        documentNumber: null,
        date: null,
        numberTTN: null,
        dateTTN: null,
        checked: false,
      };
    });
  },

  beforeMount() {
    if (this.order.reports.length > 0) {
      this.order.reports.map((report) => {
        if (report.goodId) {
          this.productReports[report.goodId] = report;
        }
        if (report.customGoodId) {
          this.productReports[report.customGoodId] = report;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>

.helper {
  white-space: wrap;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  z-index: 2;
  width: fit-content;
  top: 80%;
  left: 0;
  max-width: 200px;
  max-height: 150px;
  overflow-y: scroll;
  font: 400 12px/1.5 $roboto;
}
.option__wrapper {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}
.option__text {
  font-size: 12px;
}
.option__desc {
  display: inline-flex;
  margin-right: 3px;
  width: 10px;
  height: 10px;
  border-radius: 10px;

  &_selected {
    display: inline-flex;
    align-items: center;
    font: 300 10px $roboto;
  }
}

::v-deep {
  .vdp-datepicker {
    .dateInput {
      width: 100%;
    }

    &__calendar {
      position: fixed;
      top: 35%;
    }
  }

  .datepicker {
    display: flex;
    align-items: center;

    &__info {
      font: 400 16px $roboto;
      color: $dark-font;
      margin-right: 10px;
    }

    &__calendar {
      border-bottom: 1px solid $dark-font;
      width: 150px;
    }
  }
}

.buttons-wrap {
  display: flex;
  gap: 5px;
}

.button--save {
  display: none;
}

.orders {
  padding-top: 40px;
}

.active .orders-list.active {
  background-color: $gray-aqua;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: $admin-blue;
  }

  .button--save {
    display: block;
    background-color: inherit;
    border: 1px solid $gray-dark;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.orders-list {
  min-height: 40px;
  display: flex;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  .col {
    font: 400 16px/40px $roboto;
    color: $dark-font;
    flex-shrink: 0;
  }

  &__price {
    position: relative;

    span {
      font-size: 25px;
      color: #2467c0;
      cursor: pointer;
      position: absolute;
      margin-left: -23px;

      &.active {
        color: green;
      }
    }
  }

  &__product {
    max-height: 75px;
    overflow: visible;

    div {
      height: 77px;
      font-size: 16px;
      line-height: 22px;
      overflow: hidden;
      border-bottom: 1px solid #c8b6aa;
      padding: 8px;

      &:last-child {
        border: none;
      }
    }
  }

  &__no-border-text {
    background-color: inherit;
    font-size: 15px;
    max-width: 105px;
    border: 1px solid #2f1f1e;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: textfield;
    }
  }

  &:hover {
    background: lighten(#eeeeee, 4%);
  }
}

.orders-bot {
  margin-top: 40px;
  padding-bottom: 100px;
}

.user-order {
  margin-top: 40px;
  margin-bottom: 40px;

  &__item {
    display: flex;
    align-items: center;
  }

  &__name {
    font: 600 16px/40px $roboto;
    color: $dark-font;
    margin-right: 5px;
  }

  &__value {
    font: 400 16px/40px $roboto;
    color: $dark-font;
  }
}
</style>
