<template>
  <div class="orders-top-row">
    <div class="datepicker">
      <div class="datepicker__info">c:</div>
      <div class="datepicker__calendar">
        <datepicker :language="ru" :format="customFormatter"
          input-class="dateInput" wrapper-class="dateInputWrap" v-model="startDateValue" @selected="dateStartChangeFn" />
      </div>
    </div>

    <div class="datepicker">
      <div class="datepicker__info">по:</div>
      <div class="datepicker__calendar">
        <datepicker :language="ru" :format="customFormatter" input-class="dateInput"
          wrapper-class="dateInputWrap" v-model="endDateValue" @selected="dateEndChangeFn" />
      </div>
    </div>

    <button @click="downloadRegistry" class="orders-registry">
      Выгрузить реестр для поставщика
    </button>

    <button @click="downloadAccounting" class="orders-registry">
      Выгрузить реестр для бухгалтерии
    </button>
  </div>
</template>


<script>
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
import moment from "moment";

export default {
  components: {
    Datepicker
  },

  data() {
    return {
         ru: ru,
         startDateValue: this.startDate,
         endDateValue: this.endDate,
    };
  },

  props: {
    startDate: {
      type: Date,
      required: true
    },
    endDate: {
      type: Date,
      required: true
    },
    customFormatter: {
      type: Function,
      required: true
    },
  },
  methods: {
    dateStartChangeFn(date) {
      this.$emit('date-start-change', date);
    },

    dateEndChangeFn(date) {
      this.$emit('date-end-change', date);
    },

    downloadAccounting() {
      this.$store.dispatch("getAccountingAct", {
        from: moment(this.startDate, "DD.MM.YYYY HH:mm").format("x"),
        to: moment(this.endDate, "DD.MM.YYYY HH:mm").format("x"),
      });
    },

    downloadRegistry() {
      let start = moment(this.startDate).format("DD.MM.YYYY") + " 00:00";
      let end = moment(this.endDate).format("DD.MM.YYYY") + " 23:59";

      this.$store.dispatch("getRegistry", {
        from: moment(start, "DD.MM.YYYY HH:mm").format("x"),
        to: moment(end, "DD.MM.YYYY HH:mm").format("x"),
      });
    },
  }
};
</script>


<style lang="scss" scoped>
.orders-registry {
  display: block;
  background: $admin-blue;
  font: 400 16px/40px $roboto;
  color: $white;
  padding: 0 15px;
  border: none;
  cursor: pointer;

  &:hover {
    background: darken($admin-blue, 10%);
  }
}

.datepicker {
  display: flex;
  align-items: center;

  &__info {
    font: 400 16px $roboto;
    color: $dark-font;
    margin-right: 10px;
  }

  &__calendar {
    border-bottom: 1px solid $dark-font;
    width: 150px;
  }
}

.orders-top-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
}
</style>