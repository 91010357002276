export const fields = {
    date: {
        name: "Дата",
        style: "width: 150px;",
    },
    id: {
        name: "Номер заказа",
        style: "width: 120px;",
    },
    get: {
        name: "Поставщик",
        style: "width: 120px; text-align: center;",
    },
    user: {
        name: "Получатель",
        style: "width: 250px; text-align: center;",
    },
    reportId: {
        name: "Номер списания",
        style: "width: 140px; text-align: center;",
    },
    reportDate: {
        name: "Дата списания",
        style: "width: 130px;",
    },
    numberTTN: {
        name: "Номер ТТН",
        style: "width: 140px; text-align: center;",
    },
    dateTTN: {
        name: "Дата ТТН",
        style: "width: 120px;",
    },
    reportChecked: {
        name: "Вручен",
        style: "width: 120px;",
    },
    status: {
        name: "Статус",
        style: "width: 140px; text-align: center;",
    },
    goods: {
        name: "Товар",
        style: "width: 150px; text-align: center;",
    },
    price: {
        name: "Цена, руб.",
        style: "width: 120px; text-align: center;",
    },
    paymentMethod: {
        name: "Нал\\Безнал",
        style: "width: 120px; text-align: center;",
    },
    phone: {
        name: "Номер телефона",
        style: "width: 170px; text-align: center;",
    }
}

export const options = [
        {
          name: "На сборке",
          status: 20,
          image: require("../../../assets/images/icons/rounds/orange.svg"),
        },
        {
          name: "В пути",
          status: 30,
          image: require("../../../assets/images/icons/rounds/yellow.svg"),
        },
        {
          name: "Доставлен",
          status: 40,
          image: require("../../../assets/images/icons/rounds/green.svg"),
        },
        {
          name: "Отменен",
          status: 50,
          image: require("../../../assets/images/icons/rounds/red.svg"),
        },
      ]

export const formatDateTime = (date) => {
      const formattedDate = new Date(date);
      formattedDate.setHours(23, 59, 0, 0);

      const year = formattedDate.getFullYear();
      const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
      const day = String(formattedDate.getDate()).padStart(2, '0');
      const hours = String(formattedDate.getHours()).padStart(2, '0');
      const minutes = String(formattedDate.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }