<template>
  <div class="orders">

    <order-popup v-if="popupIsActive" @popupIsActive="popupIsActive = false" :orderUpdatePopup="popupIsActive"
      :orderId="orderId" :updateOrder="updateOrder" />

    <div class="orders-top">
      <order-filters :startDate="startDate" :endDate="endDate" :customFormatter="customFormatter"
        @date-start-change="dateStartChange" @date-end-change="dateEndChange" />

      <h1 class="orders-heading">Заказы</h1>
      <order-table 
      :status="status" 
      :options="options"
      :messageIsActive="messageIsActive"
      :startDateFilter="startDate"
      :completeDateFilter="endDate"
      :currentOrder="currentOrder"/>

      <order-details :userData="userData" :order="order" :options="options" :status="status" @download-pdf="downloadPdf"
        @according-action="accordingAction" />
    </div>
  </div>
</template>

<script>
import OrderPopup from "./components/OrderPopup.vue";
import { mapState } from "vuex";
import moment from "moment";
import OrderFilters from './components/OrderFilters.vue';
import OrderDetails from './components/OrderDetails.vue';
import OrderTable from './components/OrderTable.vue';
import { options } from "./utils";

export default {
  components: {
    OrderPopup,
    OrderFilters,
    OrderDetails,
    OrderTable
  },

  data() {
    return {
      status: {},
      orderId: null,
      isDateChanged: false,
      startDate: moment()
        .subtract(1, "months")
        .toDate(),
      endDate: moment().toDate(),
      orderUpdatePopup: false,
      currentOrder: {},
      popupIsActive: false,
      messageIsActive: false,
      options: options
    };
  },

  computed: {
    ...mapState({
      orders: (state) => state.orders.orders,
      order: (state) => state.orders.order,
      act: (state) => state.orders.act,
      userData: (state) => state.users.user,
      registry: (state) => state.orders.registry,
      accounting: (state) => state.orders.accounting,
    }),
  },

  methods: {
    convertStatus(status) {
      switch (status) {
        case 10:
          return "Выбран";
        case 20:
          return "На сборке";
        case 30:
          return "В пути";
        case 40:
          return "Доставлен";
        case 50:
          return "Отменен";
      }
    },

    customFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    downloadPdf() {
      this.$store.dispatch("getOrderAct", this.orderId).then(() => {
        setTimeout(() => {
          this.createLink(
            this.act,
            "application/pdf",
            this.userData.full_name + "_order_" + this.orderId + ".pdf"
          );
        }, 200);
      });
    },

    createLink(blob, type, name) {
      const fileURL = URL.createObjectURL(new Blob([blob], { type: type }));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", name);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },

    dateStartChange(date) {
      this.startDate = date;
      this.isDateChanged = true;
    },

    dateEndChange(date) {
      this.endDate = date;
      this.isDateChanged = true;
    },

    toTimestamp(strDate) {
      let formattedDate = Date.parse(strDate);
      return formattedDate / 100000;
    },

    async updateOrder() {
      this.popupIsActive = false;
      this.orderUpdatePopup = false;
      this.$store
        .dispatch("changeStatus", {
          orderId: this.orderId,
          price: this.currentOrder.price,
          status: this.status.status,
          onSuccess: () => {
            this.$toast("Статус заказа обновлен", {
              timeout: 2000
            })
          },
          onError: () => {
            this.$toast.error("Статус заказа не обновлен", {
              timeout: 2000
            })
          }
        })
    },

    accordingAction() {
      if (this.status.status === 40 || this.status.status === 50) {
        this.popupIsActive = true;
        this.orderUpdatePopup = true;
      } else {
        this.updateOrder();
      }
    },
  },

  watch: {
    order() {
      if (this.order != null) {
        this.status = {
          name: this.convertStatus(this.order.status),
          status: this.order.status,
          image: this.options.find((el) => el.status === this.order.status)
            .image,
        };
        this.$store.dispatch("changeStatus", {
          orderId: this.order.id,
          status: this.order.status,
          onSuccess: () => {
            this.$toast("Статус заказа можно обновить", {
              timeout: 2000
            })
          },
          onError: () => {
            this.$toast.error("Статус заказа нельзя обновить", {
              timeout: 2000
            })
          }
        });
      }
    },

    startDate() {
      this.disabledDatesEnd.to = this.startDate;
    },

    endDate() {
      this.disabledDatesStart.from = this.endDate;
    },
    // получение файла выгрузки бухгалтерии
    accounting: {
      handler(val) {
        if (val) {
          this.createLink(
            val,
            "application/vnd.ms-excel",
            `Реестр для бухгалтерии c ${this.customFormatter(
              this.startDate
            )} по ${this.customFormatter(this.endDate)}.xlsx`
          );
        }
      },
      deep: true,
    },
    // получение файла выгрузки поставщика
    registry: {
      handler(val) {
        if (val) {
          this.createLink(
            val,
            "application/vnd.ms-excel",
            `Реестр поставщика c ${this.customFormatter(
              this.startDate
            )} по ${this.customFormatter(this.endDate)}.xlsx`
          );
        }
      },
      deep: true,
    },
  },

  async created() {
    this.$store.dispatch("clearUser");
  },
};
</script>

<style lang="scss" scoped>
.orders {
  padding-top: 40px;
}

.orders-heading {
  margin-top: 40px;
  margin-bottom: 10px;
  font: 700 32px/1.5 $roboto;
  color: $dark-font;
}
</style>
