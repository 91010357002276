<template>
  <div class="home-page">
    <div class="home">
      <div class="home-bg-bot">
        <div class="home-bg-top">
          <div class="container">
            <h1 class="home__heading">Мегабонус</h1>
            <p class="home__subheading_bot">
              Участвуй в акциях, выполняй задания, копи бонусы и обменивай их на
              товары
            </p>
            <div class="home-links">
              <div class="home-links__item">

                <div class="home-notifications">
                  <transition-group name="notification" tag="div">
                    <div v-for="notification in notificationsToDisplay" :key="notification.id">
                      <notification-card :item="notification" @open="openNotificationModal"
                        @close="closeNotification" />
                    </div>
                  </transition-group>
                </div>
                <a @click="sideMenu('catalog')" class="home-links__btn-filled">Каталог товаров</a>
              </div>
              <notification-modal v-if="isModalVisible" :item="selectedNotification" :visible="isModalVisible"
                @close="closeNotificationModal" />
              <div class="home-links__item">

                <router-link to="/instructions" class="home-links__btn">Пройти инструктаж</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import NotificationCard from "../components/Notifications/NotificationCard.vue";
import NotificationModal from "../components/Notifications/NotificationModal.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    NotificationCard,
    NotificationModal,
  },
  data() {
    return {
      notifications: new Map(),
      visibleNotifications: new Map(),
      // this is a workaround vue bug (?).
      // Keep this variable in sync with 'visibleNotifications'
      notificationsToDisplay: [],
      maxVisibleNotifications: 3,
      isOpen: false,
      isModalVisible: false,
      selectedNotification: null,
    };
  },

  computed: {
    ...mapGetters([
      'isModalOpen'
    ])
  },

  created() {

    const decodedToken = jwtDecode(localStorage.getItem("jwt"));
    this.socket = io(process.env.VUE_APP_baseURL, {
      query: {
        userId: decodedToken.id,
        unreadNotifIdOffset: Number(localStorage.getItem("lastUnreadNotifId")),
      },
    });

    this.socket.on("notification", (data) => {
      this.notifications.set(data?.id, data);
      this.updateVisibleNotifications();
      this.socket.emitWithAck("read-notification", data?.id);
      localStorage.setItem("lastUnreadNotifId", data?.id);
    });

    this.socket.on("get-unread-notifications", (notifications, lastNotifId) => {
      notifications.forEach(notification => {
        this.socket.emitWithAck("read-notification", notification?.id);
        if (notification.id > lastNotifId) {
          this.notifications.set(notification.id, notification);
        }
      });
      localStorage.setItem("lastUnreadNotifId", lastNotifId);
      this.updateVisibleNotifications();
    });
  },


  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },

  methods: {
    ...mapActions([
      'openModal',
      'closeModal',
      'toggleModal'
    ]),
    updateVisibleNotifications() {
      const remainingSlots = this.maxVisibleNotifications - this.visibleNotifications.size;
      const nextNotifications = [...this.notifications.values()].slice(0, remainingSlots);
      nextNotifications.forEach((notification) => {
        this.notifications.delete(notification.id);
        this.visibleNotifications.set(notification.id, notification);
      });

      this.notificationsToDisplay = [...this.visibleNotifications.values()]
    },

    closeNotification(id) {
      this.visibleNotifications.delete(id)
      this.notifications.delete(id)

      this.updateVisibleNotifications();
    },
    sideMenu(menuClicked) {
      this.isOpen = true;
      EventBus.$emit("menuClicked", { menuClicked: menuClicked, isOpen: true });
    },
    openNotificationModal(item) {
      this.openModal('notificationModal')
      this.selectedNotification = item;
      this.isModalVisible = true;
    },
    closeNotificationModal() {
      this.closeModal('notificationModal')
      this.isModalVisible = false;
      this.selectedNotification = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-enter-active,
.notification-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.notification-enter,
.notification-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.home-page {
  position: relative;
  z-index: 1;
}

.home-notifications {
  position: absolute;
  bottom: 2px;
  right: 40px;
  z-index: 100;

  @include display-less(desktop) {
    right: 9px;
    max-height: 600px;
  }
}

.home-bg-top {
  display: block;
  z-index: 15;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 80%;
  // min-height: 100vh;

  @include display-less(qhd) {
    background-size: 90%;
  }

  @include display-less(desktop) {
    background-position: 90% bottom;
    background-size: 170% auto;
    min-height: auto;
  }

  @include display-less(smart) {
    background-position: 60% bottom;
  }
}

.home {
  min-height: calc(100vh - 184px);
  background-color: #1a1c1c;
  background-position: center;
  position: relative;
  z-index: 12;
  overflow: hidden;

  @include display-less(desktop) {
    min-height: auto;
  }

  &__heading {
    padding-top: 100px;
    font: 500 36px/1 $roboto;
    color: #fff;
    text-transform: uppercase;

    @include display-less(qhd) {
      padding-top: 50px;
    }

    @include display-less(phablet) {
      padding-top: 40px;
    }
  }

  &__subheading {
    font: 300 16px/19px $roboto;
    color: #8b8b8b;
    margin-top: 5px;
  }

  &__subheading_bot {
    font: 300 16px/19px $roboto;
    color: #fff;
    margin-top: 50px;
    max-width: 390px;

    @include display-less(phablet) {
      margin-top: 40px;
    }
  }
}

.home-links {
  display: flex;
  margin-top: 50px;
  text-align: center;
  padding-bottom: 90px;

  @include display-less(desktop) {
    padding-bottom: 470px;
  }

  @include display-less(phablet) {
    display: block;
    padding-bottom: 200px;
  }

  @include display-less(smart) {
    display: block;
    padding-bottom: 250px;
  }

  &__item {
    width: 228px;
    margin-right: 30px;

    @include display-less(phablet) {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &__btn {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 40px;
    background-color: #1a1c1c;
    border: 1px solid #ff9c43;
    border-radius: 10px;
    font: 500 18px/48px $roboto;
    color: #F47D32;
    cursor: pointer;

    &-filled {
      display: block;
      border-radius: 10px;
      cursor: pointer;
      font: 500 18px/48px $roboto;
      width: 100%;
      height: 50px;
      margin-top: 40px;
      background-color: #F47D32;
      color: white;
      margin-bottom: 10px;
    }

    @include display-less(phablet) {
      margin-top: 0px;
      width: 100%;
    }
  }
}

.home-links-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  text-align: center;

  @include display-less(phablet) {
    margin-left: 30px;
    margin-right: 30px;
    width: 40px;
  }
}

.icon-left {
  width: 70px;
  height: 70px;
  background-image: url("../assets/images/icons/icon-catalog.svg");
  background-position: center;
  background-size: contain;

  @include display-less(phablet) {
    width: 40px;
    height: 40px;
  }
}

.icon-right {
  width: 90px;
  height: 90px;
  background-image: url("../assets/images/icons/instruct.svg");
  background-position: center;
  background-size: contain;
  position: relative;
  top: 2px;

  @include display-less(phablet) {
    width: 40px;
    height: 40px;
  }
}

.home .container {
  @include display-less(desktop) {
    max-width: 486px;
  }

  @include display-less(phablet) {
    max-width: 100%;
  }
}
</style>
