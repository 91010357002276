<template>
  <div class="history">
    <div class="history-main">
      <div class="history-container">
        <h1 class="history-heading" v-if="balance">Баланс: {{ balance.data.balance }} баллов</h1>
        <div style="width: 100%;" v-if="history && history.expiringBonuses.length > 0">
          <h2 class="history-heading-md">Скоро сгорят</h2>
          <div class="history-list" v-for="(item, idx) in history.expiringBonuses" :key="idx">
            <div class="history-list__item">
              <p class="history-list__date">{{ formatDate(item.burnDate) }}</p>
            </div>
            <div class="history-list__amount">{{ item.sum }} МБ</div>
          </div>
        </div>

        <div style="width: 100%;" v-if="history && history.bonusHistory.length > 0">
          <h2 class="history-heading-history">История</h2>
          <div class="history-list-wrap">
            <div class="history-list" v-for="(item, idx) in history.bonusHistory" :key="idx">
              <div class="history-list__item">
                <div class="history-list__type">
                  <span
                    v-if="(item.order === null && item.description.length) || (item.description.length > 0 && item.order !== null)">
                    {{ item.description }}
                  </span>
                  <span v-else-if="item.order === null && item.description.length === 0 && item.ruleName === null">
                    {{ item.registratorName }}
                  </span>
                  <span v-else>
                    {{ item.ruleName }}
                  </span>

                  <span v-if="item.order" class="history-list__badge">{{ item.order.status }}</span>
                </div>
                <p class="history-list__desc">{{ formatDate(item.registratorDate.split('T')[0]) }}</p>
                <p class="history-list__desc"
                  v-if="item && item.order && item.order.purchases && item.order.purchases.goods && item.order.purchases.goods[0]">
                  {{ item.order.purchases.goods[0].name }}
                </p>
                <p class="history-list__desc" v-if="item.order === null && Number(item.totalSum) < 0">{{ item.registratorName
                  }}</p>
              </div>
              <div class="history-list__amount"
                :class="{ 'green': !item.order && item.totalSum >= 0, 'minus': item.order && item.totalSum >= 0 }">{{
                  item.totalSum }}
                МБ</div>

            </div>
            <infinite-loading @infinite="loadMoreData">
               <div slot="no-more" class="history-list__no-data"> </div>
            </infinite-loading>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'history',
  methods: {
    formatDate(date) {
      const parts = date.split('-');
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];
      const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      return `${day} ${months[month - 1]} ${year}`;
    },

    ...mapActions(['fetchHistory']),
    loadMoreData($state) {
      if (this.finished) {
        $state.complete();
        return;
      }

      this.fetchHistory()
        .then(() => {
          $state.loaded();
        })
        .catch(() => {
          $state.complete();
        });
    }
  },

  computed: {
    ...mapState({
      balance: state => state.auth.balance,
      history: state => state.history.history,
      finished: (state) => state.history.finished,
    })
  },

  created() {
    this.fetchHistory({ reset: true });
  },

}
</script>

<style lang="scss" scoped>
.history-main {
  background-color: #1A1C1C;
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
  min-height: calc(100vh - 184px);

  @include display-less(tablet) {
    padding-bottom: 120px;
  }
}

.history-list {
  display: flex;
  flex-direction: row;
  color: #FFFEFA;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__no-data {
    font: 400 10px/26px $roboto;
    color: #FFFEFA;
    opacity: 0.5;

    @include display-less(tablet) {
      font: 400 16px/26px $roboto;
    }
  }

  &-wrap {
    height: 500px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }

  &__badge {
    font: 400 15px/26px $roboto;
    padding: 0px 8px;
    background-color: #64C464;
    border-radius: 4px;
    margin-left: 5px;
    display: inline-block;
    white-space: nowrap;

    @include display-less(smart) {
      font: 400 12px/26px $roboto;

    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 500px;

    @include display-less(smart) {
      width: 200px;
    }
  }

  &__type {
    font: 400 20px/26px $roboto;
    width: 500px;

    @include display-less(smart) {
      font: 400 16px/26px $roboto;
      width: 270px;
    }
  }


  &__date {
    font: 400 20px/26px $roboto;

    @include display-less(smart) {
      font: 400 16px/26px $roboto;

    }
  }

  &__desc {
    font: 200 15px/26px $roboto;

    @include display-less(smart) {
      font: 200 13px/26px $roboto;
      width: 90vw;
    }
  }

  &__amount {
    font: 400 20px/26px $roboto;
    text-align: end;
    text-wrap: nowrap;

    @include display-less(smart) {
      font: 400 15px/26px $roboto;
      width: 300px;
    }
  }
}

.history-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  width: 100%;
  max-width: 700px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include display-less(phablet) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include display-less(smart) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.history-heading {
  font: 700 32px/26px $roboto;
  color: #FFFEFA;
  padding-top: 38px;
  margin-bottom: 40px;
  text-align: center;

  &-history {
    margin-top: 40px;
    margin-bottom: 30px;
    font: 700 30px/26px $roboto;
    color: #FFFEFA;
  }


  &-md {
    font: 500 24px/26px $roboto;
    line-height: 32px;
    color: #FFFEFA;
    padding-top: 38px;
    margin-bottom: 20px;
    text-align: left;
  }

}

.green {
  color: #64C464;

  &::before {
    content: '+';
  }
}

.minus {
  color: #FFFEFA;

  &::before {
    content: '- ';
  }
}
</style>