<template>
  <div class="orders admin-messages">
    <div class="admin-messages__head">
      <h1 class="admin-messages__heading">Сообщения</h1>
      <div class="admin-messages__list-buttons">
        <button
          class="admin-messages__button admin-messages__button--add"
          @click="toggleFormVisibility(!showForm)"
        >
          <h3>{{ showForm ? "Скрыть" : "Новое сообщение" }}</h3>
        </button>
      </div>
    </div>

    <admin-messages-table
      v-if="messages && messages.length > 0"
      :messages="messages"
      @load-more="loadMoreMessages"
      :loading="loading"
      @select-message="setMessage"
      @toggle-form="toggleFormVisibility"
    />
    <admin-read-message
      v-if="showReadMessage"
      :message="message"
      @close-form="toggleFormVisibility"
    />
    <admin-message-draft
      v-if="showDraftMessage"
      :message="message"
      @close-form="toggleFormVisibility"
    />
    <admin-messages-form v-if="showForm" @close-form="toggleFormVisibility" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AdminMessagesTable from "./components/AdminMessagesTable";
import AdminReadMessage from "./components/AdminReadMessage";
import AdminMessagesForm from "./components/AdminMessagesForm";
import AdminMessageDraft from "./components/AdminMessageDraft";

export default {
  data() {
    return {
      messageId: null,
      showForm: false,
      message: null,
    };
  },

  computed: {
    ...mapState({
      messages: state => state.adminMessages.adminMessages,
      offset: state => state.adminMessages.offset,
      limit: state => state.adminMessages.limit,
    }),
    showReadMessage() {
      return (
        this.message !== null &&
        this.message.status === "sended" &&
        !this.showForm
      );
    },
    showDraftMessage() {
      return (
        this.message !== null &&
        this.message.status === "draft" &&
        !this.showForm
      );
    },
  },

  methods: {
     loadMessages({ reset = false } = {}) {
      this.loading = true;
      this.$store.dispatch('getAllAdminMessages', { reset })
        .finally(() => {
          this.loading = false;
        });
    },
    loadMoreMessages() {
      if (!this.loading) {
        this.loadMessages();
      }
    },
    toggleFormVisibility(value) {
      this.showForm = value;
    },

    setMessage(msg) {
      this.message = msg;
    },
  },

  created() {
    this.loadMessages({ reset: true });
  },

  components: {
    AdminMessagesTable,
    AdminReadMessage,
    AdminMessagesForm,
    AdminMessageDraft,
  },
};
</script>

<style lang="scss" scoped>
.admin-messages {
  padding-bottom: 80px;

  &__head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
  }

  &__heading {
    margin-top: 30px;
    margin-bottom: 10px;
    font: 700 32px/1.5 $roboto;
    color: $dark-font;
  }

  &__list-buttons {
    display: flex;
    margin-top: 20px;

    .admin-messages__button {
      display: block;
      height: 40px;
      padding: 0 15px;
      color: $white;
      font: 400 14px/18px $roboto, sans-serif;
      background: $admin-blue;
      border: none;
      cursor: pointer;

      &--add {
        margin-right: auto;
      }

      &:hover {
        background: lighten($admin-blue, 10%);
      }
    }
  }
}
</style>
